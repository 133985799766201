import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import application from '../../../.application.json';
import gogHelper from '../../services/gogHelper';
import defaultAlbumsService from '../../services/defaultAlbumsService';
import { getSiteTranslations } from '../../config/i18n';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const {
    appParams,
    setProps,
    config,
    compId,
    platformAPIs: { bi },
  } = controllerConfig;
  const biToken = bi?.metaSiteId;
  const { environment, httpClient } = flowAPI;
  const { appName } = application;
  const { language, isViewer } = environment;
  const translations = await getSiteTranslations(language);
  const { baseUrls = {}, instance, instanceId } = appParams;
  const { getGogDataAndCovers, getDefaultAlbumCovers } = gogHelper(
    httpClient,
    isViewer,
  );

  const { getTemplateDefaultAlbums } = defaultAlbumsService(
    httpClient,
    isViewer,
  );

  let albumsCovers: GalleryItem[] = [];
  let coverIdToAlbum: { [id: string]: GogAlbum } = {};
  let gogData: GogData | { albums: GogAlbum[] } = { albums: [] };
  const visitorId = bi?.visitorId;
  const userOwnership =
    flowAPI.controllerConfig.wixCodeApi.user.currentUser.role;

  const isNewDefaultAlbums = true; // isExperimentEnabled('specs.albums.gogNewDefaultAlbums');

  const defaultAlbumsIds =
    config &&
    config.publicData &&
    config.publicData.APP &&
    config.publicData.APP.defaultAlbums &&
    config.publicData.APP.defaultAlbums.ids;

  try {
    const result = await getGogDataAndCovers(
      instanceId,
      instance,
      compId,
      defaultAlbumsIds,
      isNewDefaultAlbums,
    );
    gogData = result.gogData;
    albumsCovers = result.albumsCovers;
    coverIdToAlbum = result.coverIdToAlbum;
  } catch (e: any) {
    console.error('Failed to fetch GoG data - ' + e.message);
  }

  const setHeightFunc = (height: number) => setProps({ dimensions: height });
  return {
    async pageReady() {
      setProps({
        appName,
        cssBaseUrl: baseUrls.staticsBaseUrl,
        mobile: environment.isMobile,
        language,
        translations,
        instance,
        instanceId,
        biToken,
        setHeight: setHeightFunc,
        albumsCovers,
        gogData,
        coverIdToAlbum,
        visitorId,
        userOwnership,
        inEditor: environment.isEditor,
        fetchAlbums: async () => {
          return getGogDataAndCovers(
            instanceId,
            instance,
            compId,
            defaultAlbumsIds,
            isNewDefaultAlbums,
          );
        },
        getDefaultAlbumCovers,
        getTemplateDefaultAlbums: async () =>
          getTemplateDefaultAlbums(defaultAlbumsIds, instance, false),
      });
    },
  };
};

export default createController;
